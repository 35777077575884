import moment from "moment";

export const AG_GRID_LICENSE_KEY =
    "CompanyName=Finixio,LicensedApplication=DashFX,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-024783,ExpiryDate=22_March_2023_[v2]_MTY3OTQ0MzIwMDAwMA==8232b184263ca5869160d4e8193f977b";
export const momentFormat = "YYYY-MM-DD";
export const dateRangeOptions = [
    { label: "Specify Day", value: "one_day" },
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 Days", value: "7" },
    { label: "Month to Date", value: "month" },
    { label: moment().subtract(1, "months").format("MMM-YYYY"), value: "fullmonth" },
    { label: "Last 30 Days", value: "30" },
    { label: "Last 60 Days", value: "60" },
    { label: "Last 90 Days", value: "90" },
    { label: "Custom", value: "custom" },
];
const TODAY = moment();
const TODAYSTR = TODAY.format(momentFormat);
const YESTERDAY = moment().subtract(1, "days");
const YESTERDAYSTR = YESTERDAY.format(momentFormat);
const LASTWEEK = moment().subtract(7, "days");
const THISMONTH = moment().startOf("month");
const PREMONTHSTART = moment().subtract(1, "months").startOf("month");
const PREMONTHEND = moment().subtract(1, "months").endOf("month");
const LAST30 = moment().subtract(30, "days");
const LAST60 = moment().subtract(60, "days");
const LAST90 = moment().subtract(90, "days");

export const dateRangeArr = {
    one_day: { str: [TODAYSTR, TODAYSTR], moments: [TODAY, TODAY] },
    today: { str: [TODAYSTR, TODAYSTR], moments: [TODAY, TODAY] },
    yesterday: { str: [YESTERDAYSTR, YESTERDAYSTR], moments: [YESTERDAY, YESTERDAY] },
    7: { str: [LASTWEEK.format(momentFormat), TODAYSTR], moments: [LASTWEEK, TODAY] },
    month: { str: [THISMONTH.format(momentFormat), TODAYSTR], moments: [THISMONTH, TODAY] },
    fullmonth: {
        str: [PREMONTHSTART.format(momentFormat), PREMONTHEND.format(momentFormat)],
        moments: [PREMONTHSTART, PREMONTHEND],
    },
    30: { str: [LAST30.format(momentFormat), TODAYSTR], moments: [LAST30, TODAY] },
    60: { str: [LAST60.format(momentFormat), TODAYSTR], moments: [LAST60, TODAY] },
    90: { str: [LAST90.format(momentFormat), TODAYSTR], moments: [LAST90, TODAY] },
};
export const findDates = (method, key) => {
    if (!key) return;
    return dateRangeArr?.[key][method || "str"];
};
export const manageDate = (newValue, setState) => {
    if (!newValue) return;
    let span = newValue.value;
    if (span === "custom" || span === "one_day") return;
    let [startDate, endDate] = findDates("moments", span);
    if (setState) {
        setState({ startDate, endDate });
        return;
    } else {
        return { startDate, endDate };
    }
};

//HTTP RESPONSES
export const UNAUTHORIZED = "UNAUTHORIZED";
export const ITEM_NOT_FOUND = "ITEM_NOT_FOUND";
export const SERVER_ERROR = "SERVER_ERROR";
export const SUCCESS = "SUCCESS";
export const FAIL = "FAIL";
export const FORBIDDEN = "FORBIDDEN";
export const METHOD_NOT_ALLOWED = "METHOD_NOT_ALLOWED";
export const NETWORK_ERROR = "NETWORK_ERROR";

// ERROR MESSAGES
export const unExpectedErrorMessage = "Unexpected error occurred";
export const serverErrorMessage = "Server Error."
export const itemNotFoundErrorMessage = "Item not found";
export const unauthorizedErrorMessage = "Unauthorized Request. Please re-login. You can sign out using the button in the upper right corner.";
export const forbiddenRequestErrorMessage = "Forbidden Request";
export const failErrorMessage = "Process failed";
export const networkErrorMessage = "Network error. Please try again.";
export const generalSuccessMessage = "Process successfully completed";

//VALIDATION MESSAGES
export const requiredField = "Required field";
